
import { computed, defineComponent, onMounted } from 'vue'

import PointsCard from './PointsCard.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import AcExpansionPanels from '@ui-kit/components/AcExpansionPanels.vue'
import AcExpansionPanel from '@ui-kit/components/AcExpansionPanel.vue'
import { useStore } from '@web/store'
import { pointsInfo } from '@web/common/ExternalConfig/getters'

export default defineComponent({
  name: 'AcPoints',
  components: {
    AcExpansionPanel,
    AcExpansionPanels,
    AcButton,
    PointsCard
  },
  setup () {
    const store = useStore()

    const userScoreAmount = computed(() => store.getters['userScore/amount'])
    const faq = computed(() => pointsInfo().faq)
    const isShowFaq = computed(() => pointsInfo().faq.length)
    const countPointsToDebit = computed(() => pointsInfo().countPointsToDebit)
    const linkHowItWork = computed(() => pointsInfo().links.howItWork || '')

    function handlerMoreDetail () {
      if (linkHowItWork.value) {
        window.open(linkHowItWork.value, '_system')
      }
    }

    onMounted(() => {
      store.dispatch('userScore/amount')
    })

    return {
      userScoreAmount,
      faq,
      isShowFaq,
      countPointsToDebit,
      linkHowItWork,
      handlerMoreDetail
    }
  }
})
