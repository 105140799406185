
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { communityUrlFrontend, pointsInfo } from '@web/common/ExternalConfig/getters'
import { useStore } from '@web/store'
import { SpendPointsWithClub } from '@web/store/types/modules/assets'
import { getUrlWithAuthParams } from '@web/common/Utils'

import AcButton from '@ui-kit/components/AcButton.vue'

export default defineComponent({
  name: 'PointsCard',
  components: {
    AcButton
  },
  props: {
    points: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()

    const countPointsToDebit = computed(() => pointsInfo().countPointsToDebit)
    const linkForSpendPointsWithClub = computed <SpendPointsWithClub | string>(() => pointsInfo().links.spendPointsWithClub || ({
      expired: '',
      recommended: '',
      owned: ''
    }))
    const linkForSpendPointsWithoutClub = computed(() => {
      let link = pointsInfo().links.spendPointsWithoutClub
      if (!link) {
        link = typeof linkForSpendPointsWithClub.value === 'string' ? linkForSpendPointsWithClub.value : linkForSpendPointsWithClub.value.recommended || ''
      }
      return link
    })
    const linkForSpendPoints = computed(() => {
      const communities = store.getters['community/communities'].filter(c => c.userCommunity || c.type === 'recommended')

      if (!communityUrlFrontend() || !communities.length) {
        return linkForSpendPointsWithoutClub.value
      }

      if (communities.some(c => c.type === 'owned')) {
        return typeof linkForSpendPointsWithClub.value === 'string' ? linkForSpendPointsWithClub.value : linkForSpendPointsWithClub.value.owned || ''
      }
      if (communities.some(c => c.type === 'expired')) {
        return typeof linkForSpendPointsWithClub.value === 'string' ? linkForSpendPointsWithClub.value : linkForSpendPointsWithClub.value.expired || ''
      }
      return typeof linkForSpendPointsWithClub.value === 'string' ? linkForSpendPointsWithClub.value : linkForSpendPointsWithClub.value.recommended || ''
    })
    const isNotEnoughPoints = computed(() => props.points < countPointsToDebit.value)

    function goToHistory () {
      router.push({
        name: 'points-history'
      })
    }

    function handlerClick () {
      if (!isNotEnoughPoints.value && linkForSpendPoints.value) {
        window.open(getUrlWithAuthParams(linkForSpendPoints.value), '_system')
      }
    }

    return {
      countPointsToDebit,
      isNotEnoughPoints,
      goToHistory,
      handlerClick
    }
  }
})
