
import { ref, computed, defineComponent } from 'vue'
import { MqResponsive } from 'vue3-mq'
import i18n from '@web/plugins/i18n'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AcPoints from '@web/components.v2/Points/index.vue'

import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  name: 'Points',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsFooter,
    AsSpacer,
    AsBreadcrumb,
    AcPoints
  },
  setup () {
    const store = useStore()
    const isOpenMenu = ref(false)

    useMeta({
      title: i18n.global.t('Points')
    })

    const menu = computed(() => store.getters['menu/general'])

    const prevPage = { name: PAGE_NAME.HOME }

    return {
      isOpenMenu,
      menu,
      prevPage
    }
  }
})
